import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { Store } from '@ngrx/store';

import { PrivaDialogButton } from '@priva/components/dialog-simple';

import { BlockingDialog } from 'app/shared/dialogs';
import { AppActions, AppState } from 'app/state';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackComponent extends BlockingDialog {
    private readonly store$ = inject<Store<{ app: AppState }>>(Store);
    public titleKey = input<string>();

    public subTitleKey = input<string>();

    public textKey = input<string>();

    public textParams = input<object>();

    public buttons: PrivaDialogButton[] = [
        {
            id: 'ok',
            importance: 'secondary',
            text: 'GLOBAL.ACTION.OK',
        },
    ];

    constructor() {
        super();
        this.store$.dispatch(AppActions.clearAllThrobbers());
    }

    /* istanbul ignore next lvb, just dispatch single action */
    public close() {
        this.store$.dispatch(AppActions.closeDialog({}));
    }
}
